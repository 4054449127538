<template>
  <div class="home flex">
    <div class="world">
      <div class="title f31 pl40 pb30 pt50">课程推荐</div>
      <div class="content pl40 pr40">
        <div class="videoBox mb40" v-if="video[0]">
          <!-- <video :src="video[0].videoUrl" class="video" :poster="video[0].img" controls v-if="video.length && video[0].videoUrl"></video> -->
          <video :src="video[0].videoUrl" class="video" id="media" controls autoplay muted v-if="video.length && video[0].videoUrl"></video>
          <div class="chose" v-if="endVideo">
            <div class="playerImg cup" @click="goPlay"><img src="../../assets/img/player.png" ></div>
            <div class="time">{{videoLenght}}</div>
            <div class="look">点击查看<span class="cup" @click="goVideo">[完整视频]</span></div>
          </div>
        </div>
        <div class="base" v-if="ads.length">
          <div class="activity" v-for="(item, index) in ads" :key="index">
            <div class="bgs" style="background-color: #fff000;"></div>
            <div class="doing">
              <div class="naver flex row-between pb15">
                <div class="letter f22 c333">{{item.adsInfo.name}} <span class="f14 pl15">{{item.adsInfo.subtitle}}</span></div>
                <div class="more f12 c666 cup" @click="goMore(item)">MORE <i class="el-icon-caret-right"></i></div>
              </div>
              <div class="list w100 flex" v-if="item.collectionInfo && item.collectionInfo.length">
                <template v-for="(dt, idx) in item.collectionInfo">
                  <div class="item cup" :key="idx" v-if="idx < 3" @click="goChoice(dt)"><img :src="dt.images" ></div>
                </template>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <investigation></investigation>
  </div>
</template>

<script>

import investigation from '../../components/investigation.vue';
export default {
  name: 'home',
  components: {
    investigation
  },
  data(){
    return{
      ads: '',
      endVideo: false,
      ele: null,
      video: '',
      videoLenght: 0
    }
  },
  mounted() {
    this.getHome();
    var that = this;
    setTimeout(() => {
      that.ele = document.getElementById("media");
      that.ele.addEventListener('ended',function(){  
        if(that.ele.ended){
          that.endVideo = that.ele.ended;
        }
      });
      that.videoLenght = this.formatSeconds(that.ele.duration);
    },2000)
  },
  computed: {
    user () {
      return this.$store.state.stateUser;
    }
  },
  methods:{
    async getHome(){
      var res = await this.$api.get('/api/project_index/index');
      if(res.code == 0){
        var data = res.data;
        this.video = data.video;
        this.ads = data.ads;
      }
    },
    
    formatSeconds(value) {
      var secondTime = parseInt(value);
      var minuteTime = 0;
      var hourTime = 0;
      var dayTime = 0;
      var result = '';
      if (value < 60) {
        result = '00:'+secondTime;
      } else {
        if (secondTime >= 60) {
          minuteTime = parseInt(secondTime / 60);
          secondTime = parseInt(secondTime % 60);
          if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
            if (hourTime >= 24) {
              dayTime = parseInt(hourTime / 24);
              hourTime = parseInt(hourTime % 24);
            }
          }
        }
        if (secondTime > 0) {
          secondTime = parseInt(secondTime) >= 10 ? secondTime : '0' + secondTime;
          result = '' + secondTime + '';
        }
        if (minuteTime > 0) {
          minuteTime = parseInt(minuteTime) >= 10 ? minuteTime : '0' + minuteTime;
          result = '' + minuteTime + ':' + result;
        }
        if (hourTime > 0) {
          result = '' + parseInt(hourTime) + ':' + result;
        }
        if (dayTime > 0) {
          result = '' + parseInt(dayTime) + ':' + result;
        }
      }
      return result
    },
    goPlay(){
      this.endVideo = false;
      this.ele.play();
    },
    goVideo(){
      if(this.video[0].clickType == 'NONE') return;
      if(this.video[0].clickType == 'INDEX') return;
      if(this.video[0].clickType == 'VIDEO'){
        this.$router.push('/home/video?id='+this.video[0].clickParams);
      }
      if(this.video[0].clickType == 'URL'){
        window.open(this.video[0].clickParams);
      }
    },
    goMore(item){
      var id = item.adsInfo.id;
      this.$router.push('/home/more?id='+id);
    },
    
    goChoice(item){
      var id = item.collectionId;
      this.$router.push('/home/choice?id='+id);
    }
  }
}
</script>

<style lang="scss" scoped>
  .home{
    width: 100%;
    height: 100%;
    
    .world{
      text-align: left;
      width: 71%;
      min-width: 792px;
      height: 100%;
      .content{
        width: 100%;
        height: calc(100% - 165px);
        overflow: auto;
        .base .activity{
          position: relative;
          padding-top: 54px;
          &:first-child{
            padding-top: 0;
          }
          .bgs{
            width: 100%;
            height: 127px;
            position: absolute;
            bottom: -25px;
            left: 17px;
            border-radius: 15px;
            z-index: 1;
          }
          .doing{
            position: relative;
            z-index: 2;
            .more{
              width: 82px;
              height: 24px;
              line-height: 24px;
              border-radius: 6px;
              background-color: #f1f0f1;
              text-align: center;
            }
            .list{
              height: 200px;
              .item{
                width: 346px;
                height: 200px;
                margin-right: 12px;
                overflow: hidden;
                position: relative;
                img{
                  width: 100%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &:last-child{
                  margin-right: 0;
                }
              }
            }
          }
        }
        .videoBox{
          width: 100%;
          // height: 480px;
          // background-color: #EEEEEE;
          // line-height: 480px;
          text-align: center;
          position: relative;
          .chose{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            z-index: 99;
            font-size: 24px;
            color: #FFFFFF;
            font-size: 20px;
            line-height: 1;
            .playerImg{
              width: 67px;
              height: 67px;
              margin: 0 auto;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .time{
              text-align: center;
              padding-top: 10px;
              padding-bottom: 20px;
            }
            .look{
              text-align: center;
              font-size: 18px;
              span{
                color: #ffe700;
                cursor: pointer;
              }
            }
          }
          .video{
            width: 100%;
            height: 100%;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .title{
        position: relative;
        width: 100%;
        font-weight: bold;
      }
    }
  }
</style>
